import React, { useEffect, useState } from 'react';
import { BsFillArchiveFill, BsFillGrid3X3GapFill, BsPeopleFill } from 'react-icons/bs';
import { PieChart } from '@mui/x-charts/PieChart';
import { getFlicks } from '../api/api'; // Adjust the path as needed

function Flicks() {
  const [totalFlicks, setTotalFlicks] = useState(0);
  const [dailyFlicks, setDailyFlicks] = useState(0);
  const [weeklyFlicks, setWeeklyFlicks] = useState(0);
  const [monthlyFlicks, setMonthlyFlicks] = useState(0);

  useEffect(() => {
    const fetchFlicks = async () => {
      try {
        const data = await getFlicks();

        setTotalFlicks(data.totalFlicksCount);
        setDailyFlicks(data.dailyFlicksCount);
        setWeeklyFlicks(data.weeklyFlicksCount);
        setMonthlyFlicks(data.monthlyFlicksCount);
      } catch (error) {
        console.error('Error fetching flick data:', error);
      }
    };

    fetchFlicks();
  }, []);

  const chartData = [
    { id: 0, value: totalFlicks, label: 'Total Flicks' },
    { id: 1, value: dailyFlicks, label: 'Daily Flicks' },
    { id: 2, value: weeklyFlicks, label: 'Weekly Flicks' },
    { id: 3, value: monthlyFlicks, label: 'Monthly Flicks' },
  ];

  return (
    <main className="main-container">
      <div className="main-cards">
        <div className="card">
          <div className="card-inner">
            <h3 className="card-text">Total Flicks</h3>
            <BsFillArchiveFill className="card_icon" />
          </div>
          <h1 className="card-inner">{totalFlicks}</h1>
        </div>
        <div className="card">
          <div className="card-inner">
            <h3 className="card-text">Daily Flicks</h3>
            <BsFillGrid3X3GapFill className="card_icon" />
          </div>
          <h1 className="card-inner">{dailyFlicks}</h1>
        </div>
        <div className="card">
          <div className="card-inner">
            <h3 className="card-text">Weekly Flicks</h3>
            <BsPeopleFill className="card_icon" />
          </div>
          <h1 className="card-inner">{weeklyFlicks}</h1>
        </div>
        <div className="card">
          <div className="card-inner">
            <h3 className="card-text">Monthly Flicks</h3>
            <BsPeopleFill className="card_icon" />
          </div>
          <h1 className="card-inner">{monthlyFlicks}</h1>
        </div>
      </div>
      <div className="cardS">
        <PieChart
          series={[
            {
              data: chartData,
            },
          ]}
          //width={450}
          height={300}
        />
      </div>
    </main>
  );
}

export default Flicks;
