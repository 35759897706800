import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TablePagination,
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { MdBlockFlipped } from "react-icons/md";
import { searchUsers } from "../api/api"; // Adjust the path as needed
import {
  BsFillArchiveFill,
  BsFillGrid3X3GapFill,
  BsPeopleFill,
} from "react-icons/bs";

function FollowersFollowings() {
  const [query, setQuery] = useState("");
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleSearch = async (val) => {
    setQuery(val);
    try {
      const searchResult = await searchUsers(val); // Call the API function
      setRows(searchResult.searchResult);
      console.log(searchResult.searchResult)
    } catch (error) {
      console.error("Error occurred while searching for users:", error);
    }
  };

  const initialRows = [
    {
      id: 1,
      email: "john.doe@example.com",
      username: "johndoe",
      phone: "123-456-7890",
      gender: "Male",
      userId: "JD123",
      followerCategories: {
        friends: [],
        family: [],
        celebrity: [],
        others: []
      },
      followings: ["following1", "following2"],
    },
    {
      id: 2,
      email: "jane.smith@example.com",
      username: "janesmith",
      phone: "987-654-3210",
      gender: "Female",
      userId: "JS456",
      followerCategories: {
        friends: [],
        family: [],
        celebrity: [],
        others: []
      },
      followings: ["following3"],
    },
    // Add more rows as needed
  ];

  const highlightText = (text, query) => {
    if (!query) return text;
    const parts = text.split(new RegExp(`(${query})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === query.toLowerCase() ? (
        <span key={index} className="highlight">
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const currentRows = rows.length ? rows : initialRows;

  return (
    <main className="main-container">
      <div>
        <input
          type="text"
          onChange={(e) => handleSearch(e.target.value)}
          placeholder="Search..."
        />
      </div>
      {/* Material-UI Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Username</TableCell>
              <TableCell>Phone No.</TableCell>
              <TableCell>Gender</TableCell>
              <TableCell>User Id</TableCell>
              <TableCell>Total Followers</TableCell>
              <TableCell>Total Followings</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <TableRow key={row._id || row.id}>
                <TableCell>{highlightText(row.email, query)}</TableCell>
                <TableCell>{highlightText(row.username, query)}</TableCell>
                <TableCell>{highlightText(row.phone, query)}</TableCell>
                <TableCell>{highlightText(row.gender, query)}</TableCell>
                <TableCell>{highlightText(row._id || row.userId, query)}</TableCell>
                <TableCell>
                  {Object.values(row.followerCategories).reduce((acc, category) => acc + category.length, 0)}
                </TableCell>
                <TableCell>{row.followings.length}</TableCell>
                <TableCell>
                  <IconButton style={{ color: "#19CA9F" }} aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                  <IconButton style={{ color: "#FF5035" }} aria-label="report">
                    <MdBlockFlipped />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={currentRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </main>
  );
}

export default FollowersFollowings;
