// src/api/api.js
import axios from 'axios';

const API_BASE_URL = 'http://localhost:3000/api/admin';

const getAuthHeader = () => {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('No token found');
  }
  return {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
  };
};


export const login = async (email, password) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/Login`, { email, password });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Network error');
  }
};

export const searchUsers = async (query) => {
  const token = localStorage.getItem('token'); // Get the token from local storage
  console.log('Token:', token); // Debugging
  if (!token) {
    throw new Error('No token found');
  }
  try {
    const response = await axios.get(`${API_BASE_URL}/search/${query}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error occurred while searching for users:', error); // Debugging
    throw error.response ? error.response.data : new Error('Network error');
  }
};

export const getActiveUsers = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/activeUsers`, {
      headers: getAuthHeader(),
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Network error');
  }
};

export const getInactiveUsers = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/inactiveUsers`, {
      headers: getAuthHeader(),
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Network error');
  }
};

export const getPosts = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/posts`, {
      headers: getAuthHeader(),
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Network error');
  }
};

export const getFlicks = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/flicks`, {
      headers: getAuthHeader(),
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Network error');
  }
};

export const getStories = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/stories`, {
      headers: getAuthHeader(),
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Network error');
  }
};

export const getReports = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/reports`, {
      headers: getAuthHeader(),
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Network error');
  }
};

export const getDailyReports = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/dailyreports`, {
      headers: getAuthHeader(),
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Network error');
  }
};

export const createAdCard = async (adCardData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/adcards`, adCardData, {
      headers: getAuthHeader(),
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Network error');
  }
};

export const getAdCards = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/getAllAdcards`, {
      headers: getAuthHeader(),
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Network error');
  }
};

export const getAdCardById = async (adCardId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/AllAdcards/${adCardId}`, {
      headers: getAuthHeader(),
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Network error');
  }
};

export const updateAdCard = async (adCardId, adCardData) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/updateAdcards/${adCardId}`, adCardData, {
      headers: getAuthHeader(),
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Network error');
  }
};

export const deleteAdCard = async (adCardId) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/deleteAdcards/${adCardId}`, {
      headers: getAuthHeader(),
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Network error');
  }
};

export const deleteUserAccount = async (userId) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/deleteUserAccount/${userId}`, {
      headers: getAuthHeader(),
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Network error');
  }
};

export const reportUser = async (userId, reason) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/adminReportsUsers`, { userId, reason }, {
      headers: getAuthHeader(),
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Network error');
  }
};
