import React from 'react';
import { BsFillArchiveFill, BsFillGrid3X3GapFill, BsPeopleFill, BsSearch } from 'react-icons/bs';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { MdBlockFlipped } from 'react-icons/md';

function ChatManagement() {

  return (
    <main className='main-container'>
     
      <div style={ {   width: '296px',
    height: '40px',
    position: 'absolute',
    top: '298px',
    left: '267px',
    gap: '0px',
    borderRadius: '50px 0px 0px 0px',
    borderWidth: '0.33px 0px 0px 0px',
    opacity: '0',
    background: '#F3F3F3',
    border: '0.33px solid #1A1A1A'}}>
        <h1>Chat ChatManagement Page</h1>
    </div>
      
      
    </main>
  );
}

export default ChatManagement;
