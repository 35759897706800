import React, { useEffect, useState } from 'react';
import SubscriptionCard from '../components/cards/AdCards';
import { getAdCards, createAdCard, getAdCardById, updateAdCard, deleteAdCard } from '../api/api';
import { BsPlus, BsPencil, BsTrash } from 'react-icons/bs';
import Modal from 'react-modal';
import './AdScreen.css'; // Create a CSS file for custom styles

const AdScreen = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [currentCardId, setCurrentCardId] = useState(null);
  const [formData, setFormData] = useState({
    plans: '',
    price: '',
    duration: '',
    features: '',
  });

  useEffect(() => {
    const fetchAdCards = async () => {
      try {
        const data = await getAdCards();
        if (data && data.adCardPlans) {
          const formattedSubscriptions = data.adCardPlans.map(plan => ({
            id: plan._id,
            plan: plan.plans,
            price: `₹${plan.price}`,
            duration: plan.duration,
            benefits: plan.features
          }));
          setSubscriptions(formattedSubscriptions);
        } else {
          throw new Error('Invalid response structure');
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAdCards();
  }, []);

  const handleOpenModal = () => {
    setIsEdit(false);
    setFormData({ plans: '', price: '', duration: '', features: '' });
    setIsModalOpen(true);
  };

  const handleEditClick = async (adCardId) => {
    try {
      const data = await getAdCardById(adCardId);
      const adCard = data.adCard; // Ensure this matches the response structure
      if (adCard) {
        setFormData({
          plans: adCard.plans,
          price: adCard.price,
          duration: adCard.duration,
          features: adCard.features.join(', ')
        });
        setCurrentCardId(adCardId);
        setIsEdit(true);
        setIsModalOpen(true);
      } else {
        throw new Error('Invalid response structure');
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const handleDeleteClick = async (adCardId) => {
    try {
      await deleteAdCard(adCardId);
      const updatedSubscriptions = subscriptions.filter(subscription => subscription.id !== adCardId);
      setSubscriptions(updatedSubscriptions);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { plans, price, duration, features } = formData;
    const featuresArray = features.split(',').map(feature => feature.trim());
    try {
      if (isEdit) {
        await updateAdCard(currentCardId, { plans, price, duration, features: featuresArray });
      } else {
        await createAdCard({ plans, price, duration, features: featuresArray });
      }
      const data = await getAdCards(); // Refresh the ad cards list
      const formattedSubscriptions = data.adCardPlans.map(plan => ({
        id: plan._id,
        plan: plan.plans,
        price: `₹${plan.price}`,
        duration: plan.duration,
        benefits: plan.features
      }));
      setSubscriptions(formattedSubscriptions);
      handleCloseModal();
    } catch (error) {
      setError(error.message);
    }
  };

  const screenStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    background: '#f0f0f0',
    minHeight: '100vh',
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <main className='main-container'>
      <div style={screenStyle}>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
          <h2 style={{color:"black"}}>Subscription Plans</h2>
          <button onClick={handleOpenModal} style={{ fontSize: '24px', cursor: 'pointer', border: 'none', background: 'none' }}>
            Add New Card<BsPlus />
          </button>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', width: '100%' }}>
          {subscriptions.map((subscription, index) => (
            <div key={index} style={{ position: 'relative', margin: '10px', padding: '10px', backgroundColor: 'white', borderRadius: '5px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', minWidth: '200px' }}>
              <SubscriptionCard
                plan={subscription.plan}
                price={subscription.price}
                duration={subscription.duration}
                benefits={subscription.benefits}
              />
              <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
                <BsPencil style={{ color:'black', marginRight: '5px', cursor: 'pointer' }} onClick={() => handleEditClick(subscription.id)} />
                <BsTrash style={{ color:'black',cursor: 'pointer' }} onClick={() => handleDeleteClick(subscription.id)} />
              </div>
            </div>
          ))}
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        contentLabel="Add or Edit Card"
        className="Modal"
        overlayClassName="Overlay"
      >
        <h2>{isEdit ? 'Edit Subscription Card' : 'Add New Subscription Card'}</h2>
        <form onSubmit={handleSubmit} className="form">
          <label>
            Plan:
            <input type="text" name="plans" value={formData.plans} onChange={handleChange} required />
          </label>
          <label>
            Price:
            <input type="text" name="price" value={formData.price} onChange={handleChange} required />
          </label>
          <label>
            Duration:
            <input type="text" name="duration" value={formData.duration} onChange={handleChange} required />
          </label>
          <label>
            Features (comma-separated):
            <input type="text" name="features" value={formData.features} onChange={handleChange} required />
          </label>
          <div className="form-buttons">
            <button type="submit">{isEdit ? 'Update Card' : 'Add Card'}</button>
            <button type="button" onClick={handleCloseModal}>Cancel</button>
          </div>
        </form>
      </Modal>
    </main>
  );
};

export default AdScreen;
