import React, { useEffect, useState } from 'react';
import { BsFillArchiveFill, BsFillGrid3X3GapFill, BsPeopleFill } from 'react-icons/bs';
import { PieChart } from '@mui/x-charts/PieChart';
import { getPosts } from '../api/api'; // Adjust the path as needed

function Posts() {
  const [totalPosts, setTotalPosts] = useState(0);
  const [dailyPosts, setDailyPosts] = useState(0);
  const [weeklyPosts, setWeeklyPosts] = useState(0);
  const [monthlyPosts, setMonthlyPosts] = useState(0);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const data = await getPosts();
console.log(data,"data")
        setTotalPosts(data.totalPostsCount);
        setDailyPosts(data.dailyPostsCount);
        setWeeklyPosts(data.weeklyPostsCount);
        setMonthlyPosts(data.monthlyPostsCount);
      } catch (error) {
        console.error('Error fetching post data:', error);
      }
    };

    fetchPosts();
  }, []);

  const chartData = [
    { id: 0, value: totalPosts, label: 'Total Uploads' },
    { id: 1, value: dailyPosts, label: 'Daily Uploads' },
    { id: 2, value: weeklyPosts, label: 'Weekly Uploads' },
    { id: 3, value: monthlyPosts, label: 'Monthly Uploads' },
  ];

  return (
    <main className="main-container">
      <div className="main-cards">
        <div className="card">
          <div className="card-inner">
            <h3 className="card-text">Total Posts Uploads</h3>
            <BsFillArchiveFill className="card_icon" />
          </div>
          <h1 className="card-inner">{totalPosts}</h1>
        </div>
        <div className="card">
          <div className="card-inner">
            <h3 className="card-text">Daily Post Uploads</h3>
            <BsFillGrid3X3GapFill className="card_icon" />
          </div>
          <h1 className="card-inner">{dailyPosts}</h1>
        </div>
        <div className="card">
          <div className="card-inner">
            <h3 className="card-text">Weekly Post Uploads</h3>
            <BsPeopleFill className="card_icon" />
          </div>
          <h1 className="card-inner">{weeklyPosts}</h1>
        </div>
        <div className="card">
          <div className="card-inner">
            <h3 className="card-text">Monthly Post Uploads</h3>
            <BsPeopleFill className="card_icon" />
          </div>
          <h1 className="card-inner">{monthlyPosts}</h1>
        </div>
      </div>
      <div className="cardS">
        <PieChart
          series={[
            {
              data: chartData,
            },
          ]}
          //width={450}
          height={300}
        />
      </div>
    </main>
  );
}

export default Posts;
