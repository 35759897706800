import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { MdBlockFlipped } from 'react-icons/md';
import { BsFillArchiveFill, BsFillGrid3X3GapFill, BsPeopleFill } from 'react-icons/bs';
import { searchUsers, getReports, getDailyReports } from '../api/api'; // Adjust the path as needed

function Reports() {
  const [query, setQuery] = useState('');
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [recentReports, setRecentReports] = useState(0);
  const [reportsMoreThan3, setReportsMoreThan3] = useState(0);
  const [reportsMoreThan5, setReportsMoreThan5] = useState(0);
  const [reportsMoreThan10, setReportsMoreThan10] = useState(0);
  const [open, setOpen] = useState(false);
  const [reportDetails, setReportDetails] = useState([]);

  useEffect(() => {
    const fetchReportData = async () => {
      try {
        const dailyReportsData = await getDailyReports();
        setRecentReports(dailyReportsData.dailyCountReports);

        const reportsData = await getReports();
        setReportsMoreThan3(reportsData.noOfusersMoreThan3Reports);
        setReportsMoreThan5(reportsData.noOfusersMoreThan5Reports);
        setReportsMoreThan10(reportsData.noOfusersMoreThan10Reports);
      } catch (error) {
        console.error('Error fetching reports data:', error);
      }
    };

    fetchReportData();
  }, []);

  const handleSearch = async (val) => {
    setQuery(val);
    try {
      const searchResult = await searchUsers(val);
      console.log(searchResult.searchResult,"search")
      setRows(searchResult.searchResult);
    } catch (error) {
      console.error('Error occurred while searching for users:', error);
    }
  };

  const highlightText = (text, query) => {
    if (!query) return text;
    const parts = text.split(new RegExp(`(${query})`, 'gi'));
    return parts.map((part, index) =>
      part.toLowerCase() === query.toLowerCase() ? (
        <span key={index} className="highlight">
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickOpen = (details) => {
    setReportDetails(details);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getNumberOfReports = (reportedUsers) => {
    return reportedUsers.length;
  };

  const getReportDetails = (reportedUsers) => {
    return reportedUsers.map((report) => ({
      reportedUserId: report.user,
      reason: report.reason,
      time: report.time,


    }));
  };

  return (
    <main className="main-container">
    <div className="card" style={{ width: '250px' }}>
          <div className="card-inner">
            <h3 className="card-text">Recent Reports</h3>
            <BsPeopleFill className="card_icon" />
          </div>
          <h1 className="card-inner">{recentReports}</h1>
        </div>
      <div className="main-cards">
        <div className="card" style={{ width: '250px' }}>
          <div className="card-inner">
            <h3 className="card-text">Total Reports more than 3 times by a user</h3>
            <BsFillArchiveFill className="card_icon" />
          </div>
          <h1 className="card-inner">{reportsMoreThan3}</h1>
        </div>
        <div className="card" style={{ width: '250px' }}>
          <div className="card-inner">
            <h3 className="card-text">Total Reports more than 5 times by a user</h3>
            <BsFillGrid3X3GapFill className="card_icon" />
          </div>
          <h1 className="card-inner">{reportsMoreThan5}</h1>
        </div>
        <div className="card" style={{ width: '250px' }}>
          <div className="card-inner">
            <h3 className="card-text">Total Reports more than 10 times by a user</h3>
            <BsPeopleFill className="card_icon" />
          </div>
          <h1 className="card-inner">{reportsMoreThan10}</h1>
        </div>
      </div>
      <div>
        <input
          type="text"
          onChange={(e) => handleSearch(e.target.value)}
          placeholder="Search..."
        />
      </div>
      {/* Material-UI Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Username</TableCell>
              <TableCell>Phone No.</TableCell>
              <TableCell>Gender</TableCell>
              <TableCell>User Id</TableCell>
              <TableCell>No. of Reports</TableCell>
              <TableCell>Message</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row._id || row.id}>
                  <TableCell>{highlightText(row.email, query)}</TableCell>
                  <TableCell>{highlightText(row.username, query)}</TableCell>
                  <TableCell>{highlightText(row.phone, query)}</TableCell>
                  <TableCell>{highlightText(row.gender, query)}</TableCell>
                  <TableCell>{highlightText(row._id || row.userId, query)}</TableCell>
                  <TableCell>{getNumberOfReports(row.reportedUsers)}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleClickOpen(getReportDetails(row.reportedUsers))} aria-label="report">
                      <BsFillArchiveFill />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton style={{ color: '#19CA9F' }} aria-label="delete">
                      <DeleteIcon />
                    </IconButton>
                    <IconButton style={{ color: '#FF5035' }} aria-label="block">
                      <MdBlockFlipped />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Report Details</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {reportDetails.map((detail, index) => (
              <div key={index}>
                <p>Reported UserId: {detail.reportedUserId}</p>
                <p>Reason: {detail.reason}</p>
                <p>Time: {new Date(detail.time).toLocaleString()}</p>
              </div>
            ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </main>
  );
}

export default Reports;
