import React from 'react';
import { BsFillArchiveFill, BsFillGrid3X3GapFill, BsPeopleFill, BsSearch } from 'react-icons/bs';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { MdBlockFlipped } from 'react-icons/md';

function Downloads() {
  // Example data
  const rows = [
    { id: 1, email: 'john.doe@example.com', username: 'johndoe', phone: '123-456-7890',userId: 'JD123', downloads: 'Active' },
    { id: 2, email: 'jane.smith@example.com', username: 'janesmith', phone: '987-654-3210', userId: 'JS456', downloads: 'Active', },
    
    { id: 1, email: 'john.doe@example.com', username: 'johndoe', phone: '123-456-7890',userId: 'JD123', downloads: 'Active' },
    { id: 2, email: 'jane.smith@example.com', username: 'janesmith', phone: '987-654-3210', userId: 'JS456', downloads: 'Active', },
    
    { id: 1, email: 'john.doe@example.com', username: 'johndoe', phone: '123-456-7890',userId: 'JD123', downloads: 'Active' },
    { id: 2, email: 'jane.smith@example.com', username: 'janesmith', phone: '987-654-3210', userId: 'JS456', downloads: 'Active', },
    
    { id: 1, email: 'john.doe@example.com', username: 'johndoe', phone: '123-456-7890',userId: 'JD123', downloads: 'Active' },
    { id: 2, email: 'jane.smith@example.com', username: 'janesmith', phone: '987-654-3210', userId: 'JS456', downloads: 'Active', },
    
    
    // Add more rows as needed
  ];

  return (
    <main className='main-container'>
      <div className='main-cards'>
        <div className='card' style={{ width: '270px', height: '146px' }}>
          <div className='card-inner'>
            <h3 className='card-text'>TOTAL Downloads</h3>
            <BsFillArchiveFill className='card_icon' />
          </div>
          <h1 className='card-inner'>300</h1>
        </div>
        <div className='card' style={{ width: '270px', height: '146px' }}>
          <div className='card-inner'>
            <h3 className='card-text'> Daily Downloads</h3>
            <BsFillGrid3X3GapFill className='card_icon' />
          </div>
          <h1 className='card-inner'>12</h1>
        </div>
        <div className='card' style={{ width: '270px', height: '146px' }}>
          <div className='card-inner'>
            <h3 className='card-text'>Weekly Downloads</h3>
            <BsPeopleFill className='card_icon' />
          </div>
          <h1 className='card-inner'>33</h1>
        </div>
        
        <div className='card' style={{ width: '270px', height: '146px' }}>
          <div className='card-inner'>
            <h3 className='card-text'>Monthly Downloads</h3>
            <BsPeopleFill className='card_icon' />
          </div>
          <h1 className='card-inner'>33</h1>
        </div>
      </div>
      <div style={ {   width: '296px',
    height: '40px',
    position: 'absolute',
    top: '298px',
    left: '267px',
    gap: '0px',
    borderRadius: '50px 0px 0px 0px',
    borderWidth: '0.33px 0px 0px 0px',
    opacity: '0',
    background: '#F3F3F3',
    border: '0.33px solid #1A1A1A'}}></div>
      {/* Material-UI Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ fontFamily: 'SF Pro Text', fontSize: 28, fontWeight: 700, lineHeight: '19.09px', textAlign: 'left' }}>
              <TableCell>Email</TableCell>
              <TableCell>Username</TableCell>
              <TableCell>Phone No.</TableCell>
              <TableCell>User Id</TableCell>
              <TableCell>Downloads</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id} sx={{ fontFamily: 'SF Pro Text', fontSize: 16, fontWeight: 500, lineHeight: '19.09px', textAlign: 'left' }}>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.username}</TableCell>
                <TableCell>{row.phone}</TableCell>
                <TableCell>{row.userId}</TableCell>
                <TableCell>{row.downloads}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      
    </main>
  );
}

export default Downloads;
