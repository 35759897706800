import { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import './App.css';
import Header from './pages/Header';
import Sidebar from './pages/Sidebar';
import Users from './pages/Users';
import Dashboard from './pages/Dashboard';
import FollowersFollowings from './pages/Followers&Followings';
import Reports from './pages/Reports';
import Downloads from './pages/Downloads';
import Stories from './pages/Stories';
import Posts from './pages/Posts';
import Flicks from './pages/Flicks';
import AdScreen from './pages/AdScreen';
import Advertisement from './pages/Advertisement';
import ActiveUsers from './pages/ActiveUsers';
import Login from './pages/Login';
import ChatManagement from './pages/ChatManagement';

const PrivateRoute = ({ element }) => {
  return localStorage.getItem('token') ? element : <Navigate to="/login" />;
};

const App = () => {
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  const location = useLocation();
  const isLoginPage = location.pathname === '/login';

  return (
    <div className="grid-container">
      {!isLoginPage && <Header OpenSidebar={OpenSidebar} />}
      {!isLoginPage && <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} />}
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<PrivateRoute element={<Dashboard />} />} />
        <Route path="/users" element={<PrivateRoute element={<Users />} />} />
        <Route path="/analytics" element={<PrivateRoute element={<FollowersFollowings />} />} />
        <Route path="/chat" element={<PrivateRoute element={<ChatManagement />} />} />
        <Route path="/reports" element={<PrivateRoute element={<Reports />} />} />
        <Route path="/downloads" element={<PrivateRoute element={<Downloads />} />} />
        <Route path="/stories" element={<PrivateRoute element={<Stories />} />} />
        <Route path="/image" element={<PrivateRoute element={<Posts />} />} />
        <Route path="/flicks" element={<PrivateRoute element={<Flicks />} />} />
        <Route path="/adview" element={<PrivateRoute element={<AdScreen />} />} />
        <Route path="/advertisement" element={<PrivateRoute element={<Advertisement />} />} />
        <Route path="/activeusers" element={<PrivateRoute element={<ActiveUsers />} />} />
        <Route path="*" element={<Navigate to="/login" />} /> {/* Redirect unknown paths to login */}
      </Routes>
    </div>
  );
};

export default App;
