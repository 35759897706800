import React, { useEffect, useState } from 'react';
import { BsFillArchiveFill, BsFillGrid3X3GapFill, BsPeopleFill } from 'react-icons/bs';
import DonutChart from 'react-donut-chart';
import { getActiveUsers, getInactiveUsers } from '../api/api'; // Adjust the path as needed

function ActiveUsers() {
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalActiveUsers, setTotalActiveUsers] = useState(0);
  const [dailyActiveUsers, setDailyActiveUsers] = useState(0);
  const [weeklyActiveUsers, setWeeklyActiveUsers] = useState(0);
  const [monthlyActiveUsers, setMonthlyActiveUsers] = useState(0);
  const [totalInactiveUsers, setTotalInactiveUsers] = useState(0);
  const [dailyInactiveUsers, setDailyInactiveUsers] = useState(0);
  const [weeklyInactiveUsers, setWeeklyInactiveUsers] = useState(0);
  const [monthlyInactiveUsers, setMonthlyInactiveUsers] = useState(0);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const activeUsersData = await getActiveUsers();
        const inactiveUsersData = await getInactiveUsers();

        setTotalUsers(activeUsersData.totalUsersCount);
        setTotalActiveUsers(activeUsersData.totalActiveUsersCount);
        setDailyActiveUsers(activeUsersData.dailyActiveUsersCount);
        setWeeklyActiveUsers(activeUsersData.weeklyActiveUsersCount);
        setMonthlyActiveUsers(activeUsersData.monthlyActiveUsersCount);

        setTotalInactiveUsers(inactiveUsersData.totalInactiveUsersCount);
        setDailyInactiveUsers(inactiveUsersData.dailyInactiveUsersCount);
        setWeeklyInactiveUsers(inactiveUsersData.weeklyInactiveUsersCount);
        setMonthlyInactiveUsers(inactiveUsersData.monthlyInactiveUsersCount);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUsers();
  }, []);

  return (
    <main className="main-container">
     <div className="card">
     
     <div>
          <div className="card-inner">
            <h3 className="card-text">Total Users</h3>
            <BsFillArchiveFill className="card_icon" />
          </div>
          <h1 className="card-inner">{totalUsers}</h1>
        </div>
        </div>
      <div className="main-cards">
        {/* <div className="card">
          <div className="card-inner">
            <h3 className="card-text">Total Users</h3>
            <BsFillArchiveFill className="card_icon" />
          </div>
          <h1 className="card-inner">{totalUsers}</h1>
        </div> */}
        <div className="card">
          <div className="card-inner">
            <h3 className="card-text">Total Active Users</h3>
            <BsFillGrid3X3GapFill className="card_icon" />
          </div>
          <h1 className="card-inner">{totalActiveUsers}</h1>
        </div>
        <div className="card">
          <div className="card-inner">
            <h3 className="card-text">Daily Active Users</h3>
            <BsFillGrid3X3GapFill className="card_icon" />
          </div>
          <h1 className="card-inner">{dailyActiveUsers}</h1>
        </div>
        <div className="card">
          <div className="card-inner">
            <h3 className="card-text">Weekly Active Users</h3>
            <BsPeopleFill className="card_icon" />
          </div>
          <h1 className="card-inner">{weeklyActiveUsers}</h1>
        </div>
        <div className="card">
          <div className="card-inner">
            <h3 className="card-text">Monthly Active Users</h3>
            <BsPeopleFill className="card_icon" />
          </div>
          <h1 className="card-inner">{monthlyActiveUsers}</h1>
        </div>
        <div className="card">
          <div className="card-inner">
            <h3 className="card-text">Total Inactive Users</h3>
            <BsFillArchiveFill className="card_icon" />
          </div>
          <h1 className="card-inner">{totalInactiveUsers}</h1>
        </div>
        <div className="card">
          <div className="card-inner">
            <h3 className="card-text">Daily Inactive Users</h3>
            <BsFillGrid3X3GapFill className="card_icon" />
          </div>
          <h1 className="card-inner">{dailyInactiveUsers}</h1>
        </div>
        <div className="card">
          <div className="card-inner">
            <h3 className="card-text">Weekly Inactive Users</h3>
            <BsPeopleFill className="card_icon" />
          </div>
          <h1 className="card-inner">{weeklyInactiveUsers}</h1>
        </div>
        <div className="card">
          <div className="card-inner">
            <h3 className="card-text">Monthly Inactive Users</h3>
            <BsPeopleFill className="card_icon" />
          </div>
          <h1 className="card-inner">{monthlyInactiveUsers}</h1>
        </div>
      </div>
      <div className="cardS">
        <DonutChart
          data={[
            {
              label: 'Inactive Users',
              value: totalInactiveUsers,
            },
            {
              label: 'Active Users',
              value: totalActiveUsers,
            },
          ]}
        />
      </div>
    </main>
  );
}

export default ActiveUsers;
