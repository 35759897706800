import React from 'react'
import 
{ BsFillArchiveFill, BsFillGrid3X3GapFill, BsPeopleFill, BsFillBellFill}
 from 'react-icons/bs'
 import 
 { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line } 
 from 'recharts';

function Dashboard() {

    const data = [
        {
          name: 'Jan',
          uv: 4000,
          pv: 2400,
          amt: 2400,
        },
        {
          name: 'Feb',
          uv: 3000,
          pv: 1398,
          amt: 2210,
        },
        {
          name: 'March',
          uv: 2000,
          pv: 9800,
          amt: 2290,
        },
        {
          name: 'April',
          uv: 2780,
          pv: 3908,
          amt: 2000,
        },
        {
          name: 'May',
          uv: 1890,
          pv: 4800,
          amt: 2181,
        },
        {
          name: 'June',
          uv: 2390,
          pv: 3800,
          amt: 2500,
        },
        {
          name: 'July',
          uv: 3490,
          pv: 4300,
          amt: 2100,
        },
      ];
     

  return (
    <main className='main-container'>
       

        <div className='main-cards'>
            <div className='card'>
                <div className='card-inner'>
                    <h3 className='card-text'>Active Users</h3>
                    <BsFillArchiveFill className='card_icon'/>
                </div>
                <h1 className='card-inner'>30K</h1>
            </div>
            <div className='card'>
                <div className='card-inner'>
                    <h3 className='card-text'>Total Ad Views</h3>
                    <BsFillGrid3X3GapFill className='card_icon'/>
                </div>
                <h1 className='card-inner'>12K</h1>
            </div>
            <div className='card'>
                <div className='card-inner'>
                    <h3 className='card-text'>Total Downloads</h3>
                    <BsPeopleFill className='card_icon'/>
                </div>
                <h1 className='card-inner'>33</h1>
            </div>
            <div className='card'>
                <div className='card-inner'>
                    <h3 className='card-text'>Reports</h3>
                    <BsFillBellFill className='card_icon'/>
                </div>
                <h1 className='card-inner'>42</h1>
            </div>
        </div>
        

        <div className='charts'>
            <ResponsiveContainer width="100%" height="100%">
            <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
            }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                {/* <Bar dataKey="pv" fill="#8884d8" /> */}
                <Bar dataKey="uv" fill="#19CA9F" />
                </BarChart>
            </ResponsiveContainer>

            <ResponsiveContainer width="100%" height="100%">
                <LineChart
                width={500}
                height={300}
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
                >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="pv" stroke="#19CA9F" activeDot={{ r: 8 }} />
                {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
                </LineChart>
            </ResponsiveContainer>

        </div>
    </main>
  )
}

export default Dashboard
