import React, { useState } from "react";
import {
  BsCart3,
  BsGrid1X2Fill,
  BsFillArchiveFill,
  BsFillGrid3X3GapFill,
  BsPeopleFill,
  BsListCheck,
  BsMenuButtonWideFill,
  BsFillGearFill,
  BsFillBellFill,
  BsClockHistory,
  BsDownload,
} from "react-icons/bs";
import { CiImageOn } from "react-icons/ci";
import { FaChartLine, FaHeart, FaRegImage } from "react-icons/fa";
import { GrUserManager } from "react-icons/gr";
import { IoIosChatboxes } from "react-icons/io";
import { LiaVideoSolid } from "react-icons/lia";
import { LuUsers } from "react-icons/lu";
import { RiAdvertisementFill } from "react-icons/ri";

function Sidebar({ openSidebarToggle, OpenSidebar }) {
  const [activeItem, setActiveItem] = useState("/");

  const handleItemClick = (path) => {
    setActiveItem(path);
  };

  return (
    <aside
      id="sidebar"
      className={openSidebarToggle ? "sidebar-responsive" : ""}
    >
      <ul className="sidebar-list">
        <li className={`sidebar-list-item ${activeItem === "/" ? "active" : ""}`}>
          <a href="/" onClick={() => handleItemClick("/")}>
            <BsGrid1X2Fill className="icon" /> Dashboard
          </a>
        </li>
        <li className={`sidebar-list-item ${activeItem === "/users" ? "active" : ""}`}>
          <a href="/users" onClick={() => handleItemClick("/users")}>
            <GrUserManager className="icon" /> User Management
          </a>
        </li>
        <li className={`sidebar-list-item ${activeItem === "/chat" ? "active" : ""}`}>
          <a href="/chat" onClick={() => handleItemClick("/chat")}>
            <IoIosChatboxes className="icon" /> Chat Management
          </a>
        </li>
        <li className={`sidebar-list-item ${activeItem === "/image" ? "active" : ""}`}>
          <a href="/image" onClick={() => handleItemClick("/image")}>
            <FaRegImage className="icon" /> Image Management
          </a>
        </li>
        <li className={`sidebar-list-item ${activeItem === "/flicks" ? "active" : ""}`}>
          <a href="/flicks" onClick={() => handleItemClick("/flicks")}>
            <LiaVideoSolid className="icon" /> Flicks Management
          </a>
        </li>
        <li className={`sidebar-list-item ${activeItem === "/stories" ? "active" : ""}`}>
          <a href="/stories" onClick={() => handleItemClick("/stories")}>
            <BsClockHistory className="icon" /> Stories Management
          </a>
        </li>
        <li className={`sidebar-list-item ${activeItem === "/analytics" ? "active" : ""}`}>
          <a href="/analytics" onClick={() => handleItemClick("/analytics")}>
            <FaChartLine className="icon" /> Followers & Followings
          </a>
        </li>
        
        <li className={`sidebar-list-item ${activeItem === "/advertisement" ? "active" : ""}`}>
          <a href="/advertisement" onClick={() => handleItemClick("/advertisement")}>
            <RiAdvertisementFill className="icon" /> Ad Views
          </a>
        </li>
        <li className={`sidebar-list-item ${activeItem === "/adview" ? "active" : ""}`}>
          <a href="/adview" onClick={() => handleItemClick("/adview")}>
            <RiAdvertisementFill className="icon" /> Ad Cards
          </a>
        </li>
        <li className={`sidebar-list-item ${activeItem === "/downloads" ? "active" : ""}`}>
          <a href="/downloads" onClick={() => handleItemClick("/downloads")}>
            <BsDownload className="icon" />  Downloads
          </a>
        </li>
        <li className={`sidebar-list-item ${activeItem === "/activeusers" ? "active" : ""}`}>
          <a href="/activeusers" onClick={() => handleItemClick("/activeusers")}>
            <LuUsers className="icon" /> Active Users
          </a>
        </li>
        <li className={`sidebar-list-item ${activeItem === "/reports" ? "active" : ""}`}>
          <a href="/reports" onClick={() => handleItemClick("/reports")}>
            <BsListCheck className="icon" /> Reports
          </a>
        </li>
        <li className={`sidebar-list-item ${activeItem === "/notifications" ? "active" : ""}`}>
          <a href="/notifications" onClick={() => handleItemClick("/notifications")}>
            <BsFillBellFill className="icon" /> Notifications
          </a>
        </li>
        <li className={`sidebar-list-item ${activeItem === "/settings" ? "active" : ""}`}>
          <a href="/settings" onClick={() => handleItemClick("/settings")}>
            <BsFillGearFill className="icon" /> Settings
          </a>
        </li>
      </ul>
    </aside>
  );
}

export default Sidebar;
