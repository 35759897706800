import { Tooltip } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';
import React from "react";
import {
  BsFillArchiveFill,
  BsFillGrid3X3GapFill,
  BsPeopleFill,
  BsSearch,
} from "react-icons/bs";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis } from 'recharts';
function Advertisement() {

    

      const data = [
        {
          name: 'Total',
          uv: 4000,
          pv: 2400,
          amt: 2400,
        },
        {
          name: 'Daily',
          uv: 3000,
          pv: 1398,
          amt: 2210,
        },
        {
          name: 'Weekly',
          uv: 2000,
          pv: 9800,
          amt: 2290,
        },
        {
          name: 'Monthly',
          uv: 2780,
          pv: 3908,
          amt: 2000,
        },
        {
          name: 'Yearly',
          uv: 1890,
          pv: 4800,
          amt: 2181,
        }
      ];
      
  return (
    <main className="main-container">
     <div className="cardS">
     <ResponsiveContainer width="100%" height="100%">
            <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
            }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                {/* <Bar dataKey="pv" fill="#8884d8" /> */}
                <Bar dataKey="uv" fill="#19CA9F" />
                </BarChart>
            </ResponsiveContainer>
          </div>
      <div className="main-cards">
        <div className="card">
          <div className="card-inner">
            <h3 className="card-text">Total Views</h3>
            <BsFillArchiveFill className="card_icon" />
          </div>
          <h1 className="card-inner">300</h1>
        </div>
        <div className="card">
          <div className="card-inner">
            <h3 className="card-text">Daily Views</h3>
            <BsFillGrid3X3GapFill className="card_icon" />
          </div>
          <h1 className="card-inner">12</h1>
        </div>
        <div className="card">
          <div className="card-inner">
            <h3 className="card-text">Weekly Views</h3>
            <BsPeopleFill className="card_icon" />
          </div>
          <h1 className="card-inner">33</h1>
        </div>

        <div className="card">
          <div className="card-inner">
            <h3 className="card-text">Monthly Views</h3>
            <BsPeopleFill className="card_icon" />
          </div>
          <h1 className="card-inner">33</h1>
        </div>
      </div>
       
    </main>
  );
}

export default Advertisement;
