import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

const SubscriptionCard = ({ plan, price, duration, benefits }) => {
  const cardStyle = {
    width: '200px',       // Smaller width
    height: '250px',      // Smaller height
    border: '1px solid #ddd',
    borderRadius: '15px',
    overflow: 'hidden',
    margin: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    position: 'relative',
    padding: '15px',      // Smaller padding
    background: 'linear-gradient(to bottom right, #ffffff, #f7f7f7)',
    transition: 'transform 0.2s, box-shadow 0.2s',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  };

  const cardHoverStyle = {
    ...cardStyle,
    transform: 'scale(1.05)',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
  };

  const editIconStyle = {
    position: 'absolute',
    top: '10px',
    right: '10px',
    cursor: 'pointer',
    color: '#888',
  };

  const titleStyle = {
    fontSize: '1.2em',   // Smaller font size
    marginBottom: '10px',
    color: '#333',
  };

  const priceStyle = {
    fontSize: '1.2em',   // Smaller font size
    color: '#333',
    marginBottom: '10px',
  };

  const durationStyle = {
    fontSize: '0.9em',   // Smaller font size
    color: '#777',
    marginBottom: '10px',
  };

  const benefitStyle = {
    fontSize: '0.9em',   // Smaller font size
    color: '#555',
    marginBottom: '5px',
  };

  const [hover, setHover] = React.useState(false);

  return (
    <div
      style={hover ? cardHoverStyle : cardStyle}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div>
        <div style={titleStyle}>{plan}</div>
        <div style={priceStyle}>{price}</div>
        <div style={durationStyle}>{duration}</div>
      </div>
      <div>
        {benefits.map((benefit, index) => (
          <div key={index} style={benefitStyle}>
            {benefit}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubscriptionCard;
