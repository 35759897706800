import React, { useEffect, useState } from 'react';
import { BsFillArchiveFill, BsFillGrid3X3GapFill, BsPeopleFill } from 'react-icons/bs';
import { PieChart } from '@mui/x-charts/PieChart';
import { getStories } from '../api/api'; // Adjust the path as needed

function Stories() {
  const [totalStories, setTotalStories] = useState(0);
  const [dailyStories, setDailyStories] = useState(0);
  const [weeklyStories, setWeeklyStories] = useState(0);
  const [monthlyStories, setMonthlyStories] = useState(0);

  useEffect(() => {
    const fetchStories = async () => {
      try {
        const data = await getStories();

        setTotalStories(data.totalStoriesCount);
        setDailyStories(data.dailyStoriesCount);
        setWeeklyStories(data.weeklyStoriesCount);
        setMonthlyStories(data.monthlyStoriesCount);
      } catch (error) {
        console.error('Error fetching story data:', error);
      }
    };

    fetchStories();
  }, []);

  const chartData = [
    { id: 0, value: totalStories, label: 'Total Stories' },
    { id: 1, value: dailyStories, label: 'Daily Stories' },
    { id: 2, value: weeklyStories, label: 'Weekly Stories' },
    { id: 3, value: monthlyStories, label: 'Monthly Stories' },
  ];

  return (
    <main className="main-container">
      <div className="main-cards">
        <div className="card">
          <div className="card-inner">
            <h3 className="card-text">Total Stories</h3>
            <BsFillArchiveFill className="card_icon" />
          </div>
          <h1 className="card-inner">{totalStories}</h1>
        </div>
        <div className="card">
          <div className="card-inner">
            <h3 className="card-text">Daily Stories</h3>
            <BsFillGrid3X3GapFill className="card_icon" />
          </div>
          <h1 className="card-inner">{dailyStories}</h1>
        </div>
        <div className="card">
          <div className="card-inner">
            <h3 className="card-text">Weekly Stories</h3>
            <BsPeopleFill className="card_icon" />
          </div>
          <h1 className="card-inner">{weeklyStories}</h1>
        </div>
        <div className="card">
          <div className="card-inner">
            <h3 className="card-text">Monthly Stories</h3>
            <BsPeopleFill className="card_icon" />
          </div>
          <h1 className="card-inner">{monthlyStories}</h1>
        </div>
      </div>
      <div className="cardS">
        <PieChart
          series={[
            {
              data: chartData,
            },
          ]}
          //width={450}
          height={300}
        />
      </div>
    </main>
  );
}

export default Stories;
