import React from 'react'
import 
 {BsFillBellFill, BsFillEnvelopeFill, BsPersonCircle, BsSearch, BsJustify}
 from 'react-icons/bs'

function Header({OpenSidebar}) {
  return (
    <header className='header'>
        <div className='menu-icon'>
            <BsJustify className='icon' onClick={OpenSidebar}/>
        </div>
        <div className="sidebar-title">
  <div className="sidebar-brand">
    <img src="/LoveroomIcon.png" alt="Loveroom Icon" className="icon_header" /> LOVE<span>ROOM</span>
  </div>
</div>
        {/* <div className='header-left'>
            <div className='search-container'>
                <BsSearch className='icon'/>
                <input
                    type='text'
                    placeholder='Search'
                    className='search-input'
                />
            </div>
        </div> */}
      
        
        <div className='header-right'>

            <BsFillBellFill className='header-icon'/>
            <BsPersonCircle className='header-icon'/>
        </div>
    </header>
  )
}

export default Header
