import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TablePagination,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { MdBlockFlipped } from 'react-icons/md';
import { BsFillArchiveFill, BsFillGrid3X3GapFill, BsPeopleFill } from 'react-icons/bs';
import { searchUsers, getActiveUsers, getInactiveUsers, deleteUserAccount, reportUser } from '../api/api'; // Adjust the path as needed

function Users() {
  const [query, setQuery] = useState('');
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalUsers, setTotalUsers] = useState(0);
  const [activeUsersCount, setActiveUsersCount] = useState(0);
  const [inactiveUsersCount, setInactiveUsersCount] = useState(0);

  const [range, setRange] = useState('day'); // default range

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [activeUsersData, inactiveUsersData] = await Promise.all([
          getActiveUsers(),
          getInactiveUsers(),
        ]);
        console.log(activeUsersData, inactiveUsersData, "active inactive");
        setActiveUsersCount(activeUsersData.totalActiveUsersCount);
        setInactiveUsersCount(inactiveUsersData.totalInactiveUsersCount);
        setTotalUsers(inactiveUsersData.totalUsersCount); // Assuming total users count is the same for both
      } catch (error) {
        console.error('Error fetching users data:', error);
      }
    };

    fetchData();
  }, []);

  const handleSearch = async (val) => {
    setQuery(val);
    try {
      const searchResult = await searchUsers(val);
      setRows(searchResult.searchResult);
    } catch (error) {
      console.error('Error occurred while searching for users:', error);
    }
  };

  const handleDelete = async (userId) => {
    try {
      const response = await deleteUserAccount(userId);
      console.log(response.message);
      setRows((prevRows) => prevRows.filter((row) => row._id !== userId));
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const handleReport = async (userId) => {
    const reason = prompt("Enter the reason for reporting this user:");
    if (reason) {
      try {
        const response = await reportUser(userId, reason);
        console.log(response.message);
      } catch (error) {
        console.error('Error reporting user:', error);
      }
    }
  };

  const initialRows = [
    {
      id: 1,
      email: 'john.doe@example.com',
      username: 'johndoe',
      phone: '123-456-7890',
      gender: 'Male',
      userId: 'JD123',
      posts: ['post1', 'post2'],
    },
    {
      id: 2,
      email: 'jane.smith@example.com',
      username: 'janesmith',
      phone: '987-654-3210',
      gender: 'Female',
      userId: 'JS456',
      posts: ['post3'],
    },
    // Add more rows as needed
  ];

  const highlightText = (text, query) => {
    if (!query) return text;
    const parts = text.split(new RegExp(`(${query})`, 'gi'));
    return parts.map((part, index) =>
      part.toLowerCase() === query.toLowerCase() ? (
        <span key={index} className="highlight">
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const currentRows = rows.length ? rows : initialRows;

  return (
    <main className="main-container">
      <div className="main-cards">
        <div className="card" style={{ width: '250px' }}>
          <div className="card-inner">
            <h3 className="card-text">Total Users</h3>
            <BsFillArchiveFill className="card_icon" />
          </div>
          <h1 className="card-inner">{totalUsers}</h1>
        </div>
        <div className="card" style={{ width: '250px' }}>
          <div className="card-inner">
            <h3 className="card-text">Active Users</h3>
            <BsFillGrid3X3GapFill className="card_icon" />
          </div>
          <h1 className="card-inner">{activeUsersCount}</h1>
        </div>
        <div className="card" style={{ width: '250px' }}>
          <div className="card-inner">
            <h3 className="card-text">Inactive Users</h3>
            <BsPeopleFill className="card_icon" />
          </div>
          <h1 className="card-inner">{inactiveUsersCount}</h1>
        </div>
      </div>
      <div>
        <input
          type="text"
          onChange={(e) => handleSearch(e.target.value)}
          placeholder="Search..."
        />
      </div>
      {/* Material-UI Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Username</TableCell>
              <TableCell>Phone No.</TableCell>
              <TableCell>Gender</TableCell>
              <TableCell>User Id</TableCell>
              <TableCell>Total Posts</TableCell>
              <TableCell>Total Flicks</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentRows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row._id || row.id}>
                  <TableCell>{highlightText(row.email, query)}</TableCell>
                  <TableCell>{highlightText(row.username, query)}</TableCell>
                  <TableCell>{highlightText(row.phone, query)}</TableCell>
                  <TableCell>{highlightText(row.gender, query)}</TableCell>
                  <TableCell>{highlightText(row._id || row.userId, query)}</TableCell>
                  <TableCell>{row.posts.length}</TableCell>
                  <TableCell>0</TableCell>
                  <TableCell>
                    <IconButton
                      style={{ color: "#19CA9F" }}
                      aria-label="delete"
                      onClick={() => handleDelete(row._id || row.userId)}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <IconButton
                      style={{ color: '#FF5035' }}
                      aria-label="report"
                      onClick={() => handleReport(row._id || row.userId)}
                    >
                      <MdBlockFlipped />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={currentRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </main>
  );
}

export default Users;
